export const SCOPENAME = 'module:transactions';

// info
export const GET_TRANSACTION = `${SCOPENAME}/GET_TRANSACTION`;
export const SET_TRANSACTION = `${SCOPENAME}/SET_TRANSACTION`;
export const GET_PENDING_TRANSACTION = `${SCOPENAME}/GET_PENDING_TRANSACTION`;
export const SET_PENDING_TRANSACTION = `${SCOPENAME}/SET_PENDING_TRANSACTION`;

// list
export const GET_TRANSACTION_LIST = `${SCOPENAME}/GET_TRANSACTION_LIST`;
export const SET_TRANSACTION_LIST = `${SCOPENAME}/SET_TRANSACTION_LIST`;
export const GET_PENDING_TRANSACTION_LIST = `${SCOPENAME}/GET_PENDING_TRANSACTION_LIST`;
export const SET_PENDING_TRANSACTION_LIST = `${SCOPENAME}/SET_PENDING_TRANSACTION_LIST`;
export const GET_TRANSACTION_LIST_REQUEST = `${SCOPENAME}/GET_TRANSACTION_LIST_REQUEST`;
export const GET_TRANSACTION_LIST_SUCCESS = `${SCOPENAME}/GET_TRANSACTION_LIST_SUCCESS`;
export const GET_TRANSACTION_LIST_FAILURE = `${SCOPENAME}/GET_TRANSACTION_LIST_FAILURE`;
export const GET_TRANSACTION_LIST_IN_DELAY = `${SCOPENAME}/GET_TRANSACTION_LIST_IN_DELAY`;
export const GET_PENDING_TRANSACTION_LIST_REQUEST = `${SCOPENAME}/GET_PENDING_TRANSACTION_LIST_REQUEST`;
export const GET_PENDING_TRANSACTION_LIST_SUCCESS = `${SCOPENAME}/GET_PENDING_TRANSACTION_LIST_SUCCESS`;
export const GET_PENDING_TRANSACTION_LIST_FAILURE = `${SCOPENAME}/GET_PENDING_TRANSACTION_LIST_FAILURE`;
export const GET_PENDING_TRANSACTION_LIST_IN_DELAY = `${SCOPENAME}/GET_PENDING_TRANSACTION_LIST_IN_DELAY`;

// Address Transactions
export const GET_ADDRESS_TRANSACTIONS = `${SCOPENAME}/GET_ADDRESS_TRANSACTIONS`;
export const SET_ADDRESS_TRANSACTIONS = `${SCOPENAME}/SET_ADDRESS_TRANSACTIONS`;
export const GET_ADDRESS_TRANSACTION_LIST = `${SCOPENAME}/GET_ADDRESS_TRANSACTION_LIST`;
export const SET_ADDRESS_TRANSACTION_LIST = `${SCOPENAME}/SET_ADDRESS_TRANSACTION_LIST`;
export const GET_ADDRESS_TRANSACTION_LIST_REQUEST = `${SCOPENAME}/GET_ADDRESS_TRANSACTION_LIST_REQUEST`;
export const GET_ADDRESS_TRANSACTION_LIST_SUCCESS = `${SCOPENAME}/GET_ADDRESS_TRANSACTION_LIST_SUCCESS`;
export const GET_ADDRESS_TRANSACTION_LIST_FAILURE = `${SCOPENAME}/GET_ADDRESS_TRANSACTION_LIST_FAILURE`;
export const GET_ADDRESS_TRANSACTION_LIST_IN_DELAY = `${SCOPENAME}/GET_ADDRESS_TRANSACTION_LIST_IN_DELAY`;

// Block Transactions
export const GET_BLOCK_TRANSACTIONS = `${SCOPENAME}/GET_BLOCK_TRANSACTIONS`;
export const GET_BLOCK_TRANSACTIONS_BY_HEIGHT = `${SCOPENAME}/GET_BLOCK_TRANSACTIONS_BY_HEIGHT`;
export const SET_BLOCK_TRANSACTIONS = `${SCOPENAME}/SET_BLOCK_TRANSACTIONS`;


